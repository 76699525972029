window.onload = function() {
  let allbutton = document.getElementById("click-hide")
  if (allbutton) {
    allbutton.style.display = 'none'
    document.getElementById("quick-links").addEventListener("click", function(){
      allbutton.style.display == "none" ? allbutton.style.display = "block" : allbutton.style.display = "none"
    });
    document.querySelectorAll(".quick-button").forEach( btn => btn.addEventListener('click', function(){
      allbutton.style.display == "none" ? allbutton.style.display = "block" : allbutton.style.display = "none"
    }))
  }
};

window.onload = function() {
  var value = ''
  var timeStamp = []
  document.addEventListener("keypress",(e) => {
    var activeElement = document.activeElement;
    var inputs = ['input', 'select', 'textarea'];
    if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) === -1) {
      if( timeStamp.length > 0 && e.timeStamp - timeStamp[timeStamp.length - 1] > 30){
        value = ''
        timeStamp = []
      }
      value = value + e.key
      console.log(e.key,timeStamp.length > 0 && e.timeStamp - timeStamp[timeStamp.length - 1],e.timeStamp,value);
      if(value.indexOf('EnterC')!=-1 || value.indexOf('EnterEnter')!=-1 ){
        var barcode = value.replace('EnterC','').replace('EnterEnter','')
        value = ''
        timeStamp = []
        console.log('barcode detected',barcode)
        setCookie('barcode',barcode,2);
        window.location = window.location.origin + '/point_of_sales/new';
      }
      timeStamp.push(e.timeStamp)
    }
  })
  function setCookie(name,value,minutes) {
    var expires = "";
    if (minutes) {
        var date = new Date();
        date.setTime(date.getTime() + (minutes*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }
};
